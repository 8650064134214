<template>
    <div>
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <div class="row breadcrumbs-top d-flex">
            <h2 class="content-header-title float-start mb-0">
              Gallery Image
            </h2>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-2">
          <div class="col-12">
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb float-end">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Gallery Image</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-xl-12 col-md-12 col-12">
              <div class="card card-congratulation-medal">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="d-flex col-12">
                      <div class="float-start col-6">
                        <div class="input-group input-group-merge w-50">
                          <span class="input-group-text" id="basic-addon-search2"
                            ><vue-feather type="search"></vue-feather></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-label="Search..."
                            aria-describedby="basic-addon-search2"
                            v-model="search"
                            @keyup="searchData"
                          />
                        </div>
                      </div>
                      <!-- data-bs-toggle="modal"
                      data-bs-target="#modals-slide-in" -->
                      <div class="float-end col-6">
                        <button
                          type="button"
                          class="btn btn-gradient-primary float-end"
                          @click="add"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card" id="user-profile">
                    <div v-for="res in list" class="card-body" :key="res.id" >
                        <h3 class="mb-0 text-primary font-weight-bold font-italic">{{ res.gallery_image_type.type }}</h3>
                        <div class="row border-top" >
                            <div class="col-md-2 col-4 profile-latest-img" v-show="isImageShow" v-for="(img, key) in res.images" :key="key">
                                <a href="#" v-if='img != "" ' :class="img">
                                    <div @mouseover="selectIndex(key+'-'+res.img_type_id)" @mouseout="ItemIndex = null">
                                        <img 
                                        :src="res.url+img" 
                                        class="img-fluid rounded" 
                                        alt="avatar img" 
                                        :class="ItemIndex == (key+'-'+res.img_type_id) ? `hoverClass` : ``" 
                                        />
                                        <div id="hoverEffect" @click="deleteImage(img, res.img_type_id)">
                                            <vue-feather type="trash-2" v-show="ItemIndex == (key+'-'+res.img_type_id)" size="30"></vue-feather>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <teleport to="#modal">
      <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in" :class="isShow" :style="{display: isDisplay}">
        <div class="modal-dialog">
          <form
            class="add-new-user modal-content pt-0"
            @submit.prevent="submitForm"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          >
            <input type="hidden" name="id" :v-modal="form.id" />
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="[isShow='', isDisplay='none']"
            >
              ×
            </button>
            <div class="modal-header mb-1">
              <h4 class="modal-title" id="exampleModalLabel">
                {{ modalName }} Gallery Image
              </h4>
            </div>
            <div class="modal-body flex-grow-1">
  
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name">Image Type</label>
                <Select2 v-model="form.img_type_id" :options="$store.state.galleryImageTypeData" :settings="{ placeholder: 'Choose Image type' }" @select="imgEvent($event)"/>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: imgTypeIdError}" > Please enter Image type. </div>
              </div>
              <div class="mb-1">
                    <label class="form-label" for="basic-addon-name">Images</label>
                    <vue-dropzone
                      ref="myVueDropzone" 
                      id="dropzone" 
                      :options="dropzoneOptions"
                      @vdropzone-file-added="fileAdded"
                      @vdropzone-removed-file="fileRemove"
                    ></vue-dropzone>
              </div>
              <button
                type="submit"
                class="btn btn-primary me-1 data-submit"
                :disabled="disabled"
              >
                Submit
              </button>
              <button
                type="reset"
                class="btn btn-outline-secondary"
                :disabled="disabled"
                data-bs-dismiss="modal"
                @click="[isShow='', isDisplay='none']"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </teleport>
  </template>
  
  <script>
  import axios from "axios";
  import Form from 'vform';
  import vueDropzone from 'vue2-dropzone-vue3'
  import Select2 from 'vue3-select2-component';
  
  export default {
    name: "Image",
    components: {
      vueDropzone,
      Select2
    },
    data() {
      return {
        form: new Form({
          id: null,
          img_type_id: "",
          images: [],
        }),
        isImageShow: true,
        response: "",
        disabled: false,
        list: [],
        modalName: "Add",
        search: null,
        imgTypeIdError: "none",
        imagesError: "none",
        isShow: "",
        isDisplay: "none",
        token: localStorage.getItem('auth_token'),
        tempAttachments: [],
        attachments: [],
        htmlData: [],
        ItemIndex: null,
        showGallery: true,
        gallaryData: [],
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          acceptedFiles: ".jpeg,.jpg,.png,.gif",
          thumbnailWidth: 200,
          thumbnailHeight: 200,
          maxFilesize: 10,
          addRemoveLinks: true,
          headers: { "My-Awesome-Header": "header value" },
        }
      };
    },
    async mounted() {
      this.getResults();
      this.$store.dispatch('getGalleryImageTypeList');
    },
    methods: {
        imgEvent({ id }) {
            this.form.img_type_id = id;
            this.disabled = false;
            if(this.form.img_type_id.length > 0) {
                this.imgTypeIdError = "none";
            }
        },
        fileAdded(file) {
            console.log(file)
            this.form.images.push( file );
            let attachment = {};
            attachment._id = file.upload.uuid;
            attachment.title = file.name;
            attachment.type = "file";
            attachment.extension = "." + file.type.split("/")[1];
            attachment.user = JSON.parse(localStorage.getItem("user"));
            attachment.content = "File Upload by Select or Drop";
            attachment.thumb = file.dataURL;
            attachment.thumb_list = file.dataURL;
            attachment.isLoading = true;
            attachment.progress = null;
            attachment.size = file.size;
            this.tempAttachments = [...this.tempAttachments, attachment];
        },
        fileRemove(file) {
            this.form.images.splice(this.form.images.indexOf(file), 1)
        },
        selectIndex(Index) {
            this.ItemIndex = Index;
        },
        deleteImage(name, id) {
            this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ms-1",
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    axios.post(process.env.VUE_APP_API_URL+"/gallery_image/delete_image/" + id,
                        {
                            'name': name
                        },
                        {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": `Bearer ${this.token}`
                        },
                        }
                    )
                    .then(response => {
                        if (response.data.status) {
                            this.getResults();
                            this.$toast.success(response.data.message);
                        } else {
                            this.$toast.error(response.data.error?.[0]);
                        }
                    })
                    .catch((error) => {
                        this.$toast.error(error);
                    });
                }
            });
        },
        getShow(img) {
            if(img || img != "") {
                this.isImageShow = true
            } else {
                this.isImageShow = false
            }
        },
        async add() {
            (this.id = null),
            (this.form.img_type_id = ""),
            (this.form.images = []),
            (this.response = "");
            this.isShow = "show"
            this.isDisplay = "block"
            this.htmlData = [];
        },
        async getResults() {
            await axios
            .get(process.env.VUE_APP_API_URL+"/gallery_image", 
            {
                headers: {
                "Content-Type": "application/json",
                'Accept': "application/json",
                "Authorization": `Bearer ${this.token}`
                }
            })
            .then((response) => {
                this.list = response.data.data;
            });
        },
        async submitForm() {
            this.disabled = true;
            if (this.form.img_type_id.length === 0) {
            this.imgTypeIdError = "block"
            }  else { 
            await this.form.post(process.env.VUE_APP_API_URL+"/gallery_image",
                {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                },
                })
                .then((response) => {
                if (response.data.status) {
                    this.$toast.success(response.data.message);
                    this.isShow = ""
                    this.isDisplay = "none"
                    this.disabled = false;
                    this.getResults();
                    location.reload();
                } else {
                    this.$toast.error(response.data.error?.[0]);
                    this.disabled = false;
                }
                })
                .catch((error) => {
                this.$toast.error(error);
                this.disabled = false;
                });
            }
        },
        async searchData() {
            await axios
            .post(process.env.VUE_APP_API_URL+"/gallery_image/search",
                {
                search: this.search,
                },
                {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                },
                }
            )
            .then((response) => {
                if (response.data.status) {
                this.list = response.data.data;
                this.isShow = ""
                this.isDisplay = "none"
                } else {
                this.$toast.error(response.data.error?.[0]);
                }
            })
            .catch((error) => {
                this.$toast.error(error);
            });
        },
    },
    
  };
  </script>
  <style scoped>
  .villa_image {
    position: relative;
    opacity: 1;
  }
  .villa_image .img-fluid {
    z-index: -1;
  }
  .hoverClass {
    filter: blur(4px);
    transition: 0.2s ease-in-out;
  }
#hoverEffect i {
    cursor: pointer;
    position: absolute;
    top: 30%;
    left: 30%;
    z-index: 999;
    color: red;
    fill: white;
    font-weight: bolder;
    opacity: 1;
    font-weight: bold;
  }
  .item-img {
    padding-bottom: 0.5rem;
  }
  .wish_list a .img-fluid {
    min-height: 25rem;
    min-width: 25rem;
    max-width: 25rem;
    max-height: 25rem;
    display: flex;
    text-align: center;
  }

#user-profile .profile-latest-img {
    transition: all 0.2s ease-in-out; 
    text-align: center;
}

#user-profile .profile-latest-img:hover {
  transform: translateY(-4px) scale(1.2);
  z-index: 10; }

#user-profile .profile-latest-img img {
  margin-top: 1.28rem; }

#user-profile .block-element .spinner-border {
  border-width: 0.14rem; }

@media (max-width: 991.98px) {
  #user-profile .profile-latest-img img {
    width: 100%;
    height: 100%;
 } 
}

</style>